import React, { useEffect, useState } from 'react';
import { useBubblesState } from '../../../store/hooks/use-bubbles';
import styles from '../main-view.module.sass';
import BreadcrumbItem from './breadcrumb-item';
import { getBreadcrumb,updateParentsIds } from '../../../api';

const Breadcrumb = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const state = useBubblesState();
  const { focusedBubbleId, } = state;

  useEffect(() => {
    if (!focusedBubbleId) return;

    getBreadcrumb(focusedBubbleId).then((response) => {
      const { bubbles, endCount } = response;
      if (!Array.isArray(bubbles) || bubbles.length === 0) {
        setBreadcrumbItems([]);
        return;
      }
      let finalBubbles = bubbles;
      if (endCount > 0 && endCount <= bubbles.length) {
        const nestedBubbles = bubbles.slice(0, endCount);
        finalBubbles = [nestedBubbles, ...bubbles.slice(endCount)];
      }
      setBreadcrumbItems(finalBubbles);
    });

  }, [focusedBubbleId]);

  if (!breadcrumbItems || breadcrumbItems.length === 0) return;
  return (
    <div className={styles.path}>
      {breadcrumbItems.map((item, index) => {
        if (!item) return null;
        if (Array.isArray(item)) {
          return (
            <BreadcrumbItem
              key={`group-${index}`}
              items={item}
            />
          );
        }
        return (
          <BreadcrumbItem
            key={item.id || index}
            items={[item]}
          />
        );
      })}
    </div>
  );
};

export default Breadcrumb;
