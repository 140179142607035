import React from 'react';
import styles from './button.module.sass';

const Button = ({ label, price, icon, onClick, disabled, styles: customStyles, primary, size }) => {
	return (
		<button
			className={`button ${customStyles || ''} ${primary ? 'primary' : ''} ${disabled ? 'disabled' : ''} ${size ? `size-${size}` : ''}`}
			onClick={onClick}
			disabled={disabled}
		>
			{icon && <div className="buttonIcon">{icon}</div>}
			{(label || price) && <div className="content">
				{label && <div className="label">{label}</div>}
				{price && <div className="price">{price}</div>}
			</div>}
		</button>
	);
};

export default Button;