import React, { useEffect } from 'react';
import { useBubblesActions, useBubblesState } from '../../store/hooks/use-bubbles';
import Breadcrumb from './breadcrumb/breadcrumb';
import NameEditor from './canvas/name-editor';
import styles from './main-view.module.sass';
import { getBubbleName, setBubbleHistory } from './document/document-func';

const Title = ({ bubble }) => {
  const { updateBubble } = useBubblesActions();
  const { focusedBubbleId, focusedBubble } = useBubblesState();

  useEffect(() => {
    document.title = getBubbleName(focusedBubble) + (getBubbleName(focusedBubble).length > 0 ? " | " : "") + "Bubbles";
  }, [])

  const handleNameChange = (name) => {
    updateBubble({ id: bubble.id, properties: { name } });
    document.title = getBubbleName({name}) + (getBubbleName({name}).length > 0 ? " | " : "") + "Bubbles";
    if (focusedBubble && focusedBubbleId) {
      setBubbleHistory(focusedBubbleId, getBubbleName({name}))
    }
  };

  return (
    <div className={`${styles.headerName} header-name`}>
      <Breadcrumb />

      <NameEditor
        bubbleId={bubble.id}
        initialName={bubble.name}
        onNameChange={handleNameChange}
      />
    </div>
  );
};

export default Title;
