import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { produce } from 'immer';
import { push } from 'redux-first-history';

import { BASE_URL } from '../config';
import api from '../api';

export const getInitialBubbleId = () => `INITIAL_BID-${uuidv4()}`;
const getInitialDocBubbleId = () => `INITIAL_DOC_BID-${uuidv4()}`;

const initialBubble = {
	name: '',
	imageContent: null,
	documentChildren: [],
	canvasChildren: [],
	expanded: false,
	updatedAt: Date.now(),
	viewMode: 'hybrid',
};

const getInitialDocumentBubble = () => ({
	id: getInitialDocBubbleId(),
	...initialBubble,
});

const canvasBubbleDefaults = {
	location: { x: 10, y: 10 }, // TODO
	dimensions: { width: 300, height: 100 }, // TODO
	updateCount: 0,
};

const getInitialState = () => {
	const initialBubbleId = getInitialBubbleId();
	const initialDocBubble = getInitialDocumentBubble();
	return {
		bubbles: [
			{
				...initialBubble,
				id: initialBubbleId,
				documentChildren: [initialDocBubble.id],
			},
			initialDocBubble,
		],
		focusedBubbleId: initialBubbleId,
		focusUpdated: null,
		
		contextHistory: [],
		selectedBubbles: [],
		loading: false,
		aiLoading: false,
		uploadProgress: 0,
	};
};

// helper function for forced component updates

// for finding canvas bubbles whose doc was updated
const findDocUpdatedCanvasChildren = ({
	parentBubble,
	docBubbleId,
	bubbles,
}) => {
  // { parentBubbleId, canvasIndex }
  if (!(parentBubble?.canvasChildren)) return [];
  const updated = parentBubble.canvasChildren
    .map((c) => bubbles.find((b) => b.id === c.id))
    .map((c, i) => ({ ...c, parentBubbleId: parentBubble.id, canvasIndex: i }))
    .filter((c) => c.documentChildren?.includes(docBubbleId))
    .map(({ parentBubbleId, canvasIndex }) => ({
      parentBubbleId,
      canvasIndex,
    }));

	return updated.concat(
		parentBubble.canvasChildren
			.map((c) => bubbles.find((b) => b.id === c.id))
			.flatMap((c) =>
				findDocUpdatedCanvasChildren({ parentBubble: c, docBubbleId, bubbles }),
			),
	);
};

function traverseName(item, text) {
  if (typeof item === 'object' && item !== null) {
    for (let key in item) {
      if (key === 'text') {
        text += item[key];
      }
      traverseName(item[key], text);
    }
  }
  return text;
}

// find canvas bubbles that have some update within them
const findUpdatedCanvasBubbles = ({
	canvasBubbleId,
	parentBubble,
	bubbles,
}) => {
	// { parentBubbleId, canvasIndex }
	const updated = parentBubble.canvasChildren
		.map((c) => bubbles.find((b) => b.id === c.id))
		.map((c, i) => ({ ...c, parentBubbleId: parentBubble.id, canvasIndex: i }))
		.filter((c) => c.id === canvasBubbleId)
		.map(({ parentBubbleId, canvasIndex }) => ({
			parentBubbleId,
			canvasIndex,
		}));
	return updated.concat(
		parentBubble.canvasChildren
			.map((c) => bubbles.find((b) => b.id === c.id))
			.flatMap((c) =>
				findUpdatedCanvasBubbles({
					parentBubble: c,
					canvasBubbleId,
					bubbles,
				}),
			),
	);
};



export const handleImageGeneration = createAsyncThunk(
	'bubbles/handleImageGeneration',
	async ({ promptText }, { dispatch }) => {
		const id = uuidv4()
		dispatch(
			actions.addCanvasBubble({
				id,
				properties: {
					imageContent: "loading",
					viewMode: 'document',
				},
				location: canvasBubbleDefaults.location,
			}),
		);
		const data = await api.handleImageGeneration({ promptText });
		if (data) {
			dispatch(
				actions.updateBubble({
					id,
					properties: {
						imageContent: data.url,
					},
				}),
			);
		}
	},
);

export const savePrompt = createAsyncThunk(
	'bubbles/savePrompt',
	async ({ promptText }, { rejectWithValue }) => {
		try {
			const response = await api.savePromptToDatabase(promptText);
			dispatch(fetchLatestPrompts());
			return response;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const fetchLatestPrompts = createAsyncThunk(
	'bubbles/fetchLatestPrompts',
	async (_, { rejectWithValue }) => {
		try {
			const response = await api.fetchLatestPrompts();
			return response;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const getBubbleName = createAsyncThunk(
  'bubbles/getBubbleName',
  async ({ id }, { rejectWithValue }) => {
    try {
      const name = JSON.parse(state.bubbles.find((b) => b.id == id).name ?? '{}');
      const res = traverseName(name, "");
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const handleTextGeneration = createAsyncThunk(
  'bubbles/handleTextGeneration',
  async ({ promptText, content, parentId }, { dispatch }) => {
    const reader = await api.handleTextGeneration({ promptText, content });
    let completionText = '';
    let titleSent = false;
    if (reader) {
      let id = uuidv4();
      if (parentId) {
        id = parentId;
      }
      else {
        dispatch(
          actions.addCanvasBubble({
            id,
            location: canvasBubbleDefaults.location,
          }),
        );
      }

			const docnewid = uuidv4()
			console.log("new doc id: " + docnewid)
			dispatch(
				actions.addDocumentBubble({
					id: docnewid,
					parentBubbleId: id
				})
			)

			let { done, value } = await reader.read();

      while (!done) {
        const data = new TextDecoder('utf-8').decode(value);
        const line = data.toString();
        const message = line.replace(/^data: /, '');
        if (message === '[DONE]') {
          // Stream finished
          console.log('Stream finished');
          console.log(message);
          return;
        }
        try {
          const parsed = message;

            if (parsed) {
              completionText += parsed;
              if(titleSent){
                dispatch(
                  actions.updateBubble({
                    id,
                    properties: {
                      fromHtml: completionText,
                    },
                    updatedAt: Date.now(),
                  }),
                );
              }
              else{
                if(completionText.indexOf('|') >= 0){
                  if(!(content?.history?.length > 1)){
                  const res = completionText.split('|')[0];
                  dispatch(
                    actions.updateBubble({
                      id,
                      properties: {
                        name: JSON.stringify({id: id, type: "paragraph", props: {}, content:[{type: 'text', text: res, styles: {}}], children:[]}),
                      },
                      updatedAt: Date.now(),

                    }),   
              
                  );
                  dispatch(
                    actions.notifyCanvasBubbles({
                      id: id, canvasParentId: -1, canvasIndex: -1,
                    })   
                  );
                }
                  if(completionText.length > completionText.indexOf("|")+1){
                    completionText = completionText.slice(completionText.indexOf("|")+1)
                    dispatch(
                      actions.updateBubble({
                        id,
                        properties: {
                          fromHtml: completionText,
                        },
                        updatedAt: Date.now(),
                      }),
                    );
                  }
                  else{
                    completionText = "";
                  }
                  titleSent = true;
                }
              }
            }
          } catch (e) {
            console.log(e);
            console.log(message);
          }

				({ done, value } = await reader.read());
			}
		}
	},
);

export const signOutUser = createAsyncThunk(
	'bubbles/signOutUser',
	async (_, { dispatch }) => {
		dispatch(syncBubbles({ onLogout: true }));
		dispatch(actions.resetState());
		localStorage.removeItem('canvasIds')
		window.location = `${BASE_URL}/auth/logout`;
	},
);

// DB sync functions
export const syncBubbles = createAsyncThunk(
	'bubbles/syncBubbles',
	async ({ onLogout = false } = {}, thunkApi) => {
		const { bubbles, focusedBubbleId } = thunkApi.getState().bubbleData;
		const bubblesToSync = bubbles.filter((b) => {
			return !b.lastSyncedAt || b.updatedAt >= b.lastSyncedAt;
		});

		await api.syncBubbles({ bubbles: bubblesToSync, focusedBubbleId });

		if (onLogout) {
			thunkApi.dispatch(actions.resetState());
			return { syncedBubbles: [] };
		}

		return { syncedBubbles: bubblesToSync };
	},
);

export const loadBubbles = createAsyncThunk(
	'bubbles/loadBubbles',
	async ({ newFocusedBubbleId, isInitialLoad }, { dispatch }) => {
		const { bubbles, focusedBubbleId, focusSaved } = await api.loadBubbles({
			focusedBubbleId: newFocusedBubbleId,
		});

		if (
			(!isInitialLoad || (isInitialLoad && !newFocusedBubbleId)) &&
			focusedBubbleId !== undefined
		)
			dispatch(push('/' + focusedBubbleId));

		return { bubbles, focusedBubbleId, focusSaved };
	},
);

export const deleteBubble = createAsyncThunk(
	'bubbles/deleteBubble',
	async (selectedBubble) => {
		const message = await api.deleteBubbles({ selectedBubble });

		return message;
	},
);

export const bubblesSlice = createSlice({
  name: 'bubbles',
  initialState: getInitialState(),
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setUploadProgress: (state, { payload }) => {
      state.uploadProgress = payload;
    },
    addBubble: (state, { payload: { properties } }) => {
      const id = uuidv4();
      state.bubbles.push({
        ...initialBubble,
        id,
        ...properties,
        updatedAt: Date.now(),
      });
    },
    addCanvasBubble: {
      reducer(state, { payload: { id, properties, location, dimensions } }) {
        if (!state.bubbles.find((b) => b.id === id)) {
          // unless we're copying, add entirely new bubble
          state.bubbles.push({
            ...initialBubble,
            id,
            ...properties,
            updatedAt: Date.now(),
            parentIds: [state.focusedBubbleId]
          });
        }
        else {
          const index = state.bubbles.findIndex((b) => b.id === id);
          const orig = JSON.parse(JSON.stringify(state.bubbles.find((b) => b.id === id)));
          state.bubbles[index] = {
            ...state.bubbles[index],
            id,
            updatedAt: Date.now(),
            ...properties,
            parentIds: [...orig.parentIds, state.focusedBubbleId]
          };
        }
        const focusedBubble = state.bubbles.find(
          (b) => b.id === state.focusedBubbleId,
        );
        focusedBubble?.canvasChildren?.push({
          id,
          location: location || canvasBubbleDefaults.location,
          dimensions: dimensions || canvasBubbleDefaults.dimensions,
        });
        focusedBubble.updatedAt = Date.now();
      },
      prepare({ id = uuidv4(), properties, location }) {
        return { payload: { id, properties, location } };
      },
    },
    addDocumentBubble: {
      reducer(
        state,
        {
          payload: {
            id,
            parentBubbleId,
            properties,
            previousSibling,
            canvasParentId,
            parentCanvasIndex,
          },
        },
      ) {
        const parentId = parentBubbleId || state.focusedBubbleId;
        const parentBubble = state.bubbles.find((b) => b.id === parentId);
        if (parentBubble) {
          parentBubble.updatedAt = Date.now();
        }

        // unless we are moving a document bubble, create new
        if (!state.bubbles.find((b) => b.id === id)) {
          const newBubble = {
            id,
            canvasChildren: [],
            documentChildren: [],
            ...properties,
            updatedAt: Date.now(),
            parentIds: [parentId]
          };
          state.bubbles.push(newBubble);
        }

				if (!previousSibling) {
					parentBubble?.documentChildren?.push(id);
				} else {
					parentBubble.documentChildren = produce(
						parentBubble.documentChildren,
						(c) => {
							const previousSiblingIndex = c.findIndex(
								(c) => c === previousSibling,
							);
							c.splice(previousSiblingIndex + 1, 0, id);
						},
					);
				}
				// find any other canvas children that have this document as a child or grandchild
				// and update their updateCount
				const focusedBubble = state.bubbles.find(
					(b) => b.id === state.focusedBubbleId,
				);
				const c = findDocUpdatedCanvasChildren({
					parentBubble: focusedBubble,
					docBubbleId: id,
					bubbles: state.bubbles,
				});
				c.filter(
					(c) =>
						!(
							c.parentBubbleId === canvasParentId &&
							c.canvasIndex === parentCanvasIndex
						),
				).forEach((c) => {
					const p = state.bubbles.find((b) => b.id === c.parentBubbleId);
					if (p) {
						const u = p.canvasChildren[c.canvasIndex];
						u.updateCount = (u.updateCount || 0) + 1;
					}
				});
			},
			prepare({
				id,
				parentBubbleId,
				properties,
				previousSibling,
				canvasParentId,
				parentCanvasIndex,
			}) {
				return {
					payload: {
						id: id || uuidv4(),
						parentBubbleId,
						properties,
						previousSibling,
						canvasParentId,
						parentCanvasIndex,
					},
				};
			},
		},
		newCanvas: (state, { payload: { newBubbleId } }) => {
			const newDocBubbleId = getInitialDocBubbleId();
			const newBubble = {
				...initialBubble,
				id: newBubbleId,
				documentChildren: [newDocBubbleId],
				viewMode: 'canvas',
			};
			const newDocumentBubble = {
				...initialBubble,
				id: newDocBubbleId,
			};

			state.bubbles.push(newDocumentBubble);
			state.bubbles.push(newBubble);
			state.focusedBubbleId = newBubbleId;
			state.focusUpdated = Date.now();
		},
		newDocument: (state, { payload: { newBubbleId } }) => {
			const newDocBubbleId = getInitialDocBubbleId();
			const newBubble = {
				...initialBubble,
				id: newBubbleId,
				documentChildren: [newDocBubbleId],
				viewMode: 'document',
			};
			const newDocumentBubble = {
				...initialBubble,
				id: newDocBubbleId,
			};

			state.bubbles.push(newDocumentBubble);
			state.bubbles.push(newBubble);
			state.focusedBubbleId = newBubbleId;
			state.focusUpdated = Date.now();
		},
		// duplicates bubbles in canvas
		duplicateSelectedBubbles: (state) => {
			const focusedBubble = state.bubbles.find(
				(b) => b.id === state.focusedBubbleId,
			);

			const duplicated = state.selectedBubbles.map((canvasIndex) => {
				const original = focusedBubble.canvasChildren[canvasIndex];
				return {
					id: original.id,
					location: { x: original.x + 10, y: original.y + 10 },
					...canvasBubbleDefaults,
				};
			});
			focusedBubble.canvasChildren =
				focusedBubble.canvasChildren.concat(duplicated);
		},
		markSelectedBubbles: (state, { payload: { top, right, bottom, left } }) => {
			const focusedBubble = state.bubbles.find(
				(b) => b.id === state.focusedBubbleId,
			);
			state.selectedBubbles = focusedBubble.canvasChildren
				.map((b, canvasIndex) => ({ ...b, canvasIndex }))
				.filter(({ location: { x, y }, dimensions: { width, height } }) => {
					const minX = x;
					const maxX = x + width;
					const minY = y;
					const maxY = y + height;

					return !(maxX < left || minX > right || minY > bottom || maxY < top);
				})
				.map(({ canvasIndex }) => canvasIndex);
		},
		moveDocumentBubble: (
			state,
			{
				payload: {
					id,
					parentBubbleId,
					canvasParentId,
					parentCanvasIndex,
					oldIndex,
					newIndex,
				},
			},
		) => {
			const parentBubble = state.bubbles.find((b) => b.id === parentBubbleId);
			const canvasBubble = state.bubbles.find((b) => b.id === canvasParentId);

      if (parentBubble) {
        parentBubble.documentChildren = produce(
          parentBubble.documentChildren,
          (draft) => {
            const i = draft.findIndex((bId) => bId === id);
            if (i === oldIndex) {
              draft.splice(newIndex, 0, id);
              // remove old instance
              let offset = newIndex < oldIndex ? 1 : 0;
              draft.splice(oldIndex + offset, 1);
            }
          },
        );

				parentBubble.updatedAt = Date.now();
				const c = findDocUpdatedCanvasChildren({
					parentBubble: canvasBubble,
					docBubbleId: id,
					bubbles: state.bubbles,
				});

				c.filter(
					(c) =>
						!(
							c.parentBubbleId === canvasParentId &&
							c.canvasIndex === parentCanvasIndex
						),
				).forEach((c) => {
					const p = state.bubbles.find((b) => b.id === c.parentBubbleId);
					if (p) {
						const u = p.canvasChildren[c.canvasIndex];
						u.updateCount = (u.updateCount || 0) + 1;
					}
				});
			}
		},
		moveCanvasBubble: (state, { payload: { id, canvasIndex, delta } }) => {
			const focusedBubble = state.bubbles.find(
				(b) => b.id === state.focusedBubbleId,
			);
			const c = focusedBubble.canvasChildren[canvasIndex];
			if (c.id === id) {
				// check if move still applies at this index
				if (c) {
					c.location = {
						x: Math.max(c.location.x + delta.x, 0),
						y: Math.max(c.location.y + delta.y, 0),
					};
					c.updatedAt = Date.now();
					focusedBubble.updatedAt = Date.now();
					syncBubbles();
				}
			}
		},
		moveCanvasChildren: (
			state,
			{
				payload: {
					canvasIndices,
					fromBubbleId,
					toBubbleId,
					location = canvasBubbleDefaults.location,
				},
			},
		) => {
			const fromBubble = fromBubbleId
				? state.bubbles.find((b) => b.id === fromBubbleId)
				: state.bubbles.find((b) => b.id === state.focusedBubbleId);

			const toBubble = state.bubbles.find((b) => b.id === toBubbleId);

			const ids = fromBubble.canvasChildren
				.filter((_, i) => canvasIndices.includes(i))
				.map((b) => b.id);

			fromBubble.canvasChildren = produce(
				fromBubble.canvasChildren,
				(draft) => {
					return draft.filter((_, i) => !canvasIndices.includes(i));
				},
			);

      ids.forEach((id) => {
        toBubble.canvasChildren.push({
          id,
          ...canvasBubbleDefaults,
          location,

        });
        let original = JSON.parse(JSON.stringify(state.bubbles.find((b) => b.id === id)));
        const bubbleIndex = state.bubbles.findIndex((b) => b.id === original.id);
        const fromBubbleIdIndex = original.parentIds.findIndex((b) => b == fromBubbleId);
        if (fromBubbleIdIndex >= 0) {
          original.parentIds.splice(fromBubbleIdIndex, 1, toBubbleId)
        }
        state.bubbles[bubbleIndex] = {
          ...state.bubbles[bubbleIndex],
          updatedAt: Date.now(),
          parentIds: original.parentIds
        };
      });
      fromBubble.updatedAt = Date.now();
      toBubble.updatedAt = Date.now();
    },
    notifyCanvasBubbles: (
      state,
      { payload: { id, canvasParentId, canvasIndex } },
    ) => {
      const focusedBubble = state.bubbles.find(
        (b) => b.id === state.focusedBubbleId,
      );

			const updated = findUpdatedCanvasBubbles({
				canvasBubbleId: id,
				parentBubble: focusedBubble,
				bubbles: state.bubbles,
			});

			updated.forEach((u) => {
				if (
					u.parentBubbleId === canvasParentId &&
					u.canvasIndex === canvasIndex
				) {
					return;
				}
				const p = state.bubbles.find((b) => b.id === u.parentBubbleId);
				if (p) {
					p.canvasChildren[u.canvasIndex].updateCount =
						(p.canvasChildren[u.canvasIndex].updateCount || 0) + 1;
				}
			});
		},
		updateBubble: (state, { payload: { id, properties } }) => {
			const bubbleIndex = state.bubbles.findIndex((b) => b.id === id);
			state.bubbles[bubbleIndex] = {
				...state.bubbles[bubbleIndex],
				...properties,
				updatedAt: Date.now(),
			};
		},
		updateDocumentBubble: (
			state,
			{ payload: { id, canvasParentId, parentCanvasIndex, properties } },
		) => {
			const bubbleIndex = state.bubbles.findIndex((b) => b.id === id);
			state.bubbles[bubbleIndex] = {
				...state.bubbles[bubbleIndex],
				...properties,
				updatedAt: Date.now(),
			};

			if (parentCanvasIndex >= 0) {
				// notify updated parent documents to keep duplicate documents in sync in real time
				const focusedBubble = state.bubbles.find(
					(b) => b.id === state.focusedBubbleId,
				);

				// find any other canvas children that have this document as a child or grandchild
				// and update their updateCount
				const c = findDocUpdatedCanvasChildren({
					parentBubble: focusedBubble,
					docBubbleId: id,
					bubbles: state.bubbles,
				});

        c.filter(
          (c) =>
            !(
              c.parentBubbleId === canvasParentId &&
              c.canvasIndex === parentCanvasIndex
            ),
        ).forEach((c) => {
          const p = state.bubbles.find((b) => b.id === c.parentBubbleId);
          if (p) {
            const u = p.canvasChildren[c.canvasIndex];
            u.updateCount = (u.updateCount || 0) + 1;
          }
        });
      }
    },
    updateCanvasBubbleProperties: (
      state,
      { payload: { canvasParentId, canvasIndex, canvasProperties } },
    ) => {
      const canvasParent = state.bubbles.find((b) => b.id === canvasParentId);
      if (canvasParent?.canvasChildren) {
        canvasParent.canvasChildren[canvasIndex] = {
          ...canvasParent.canvasChildren[canvasIndex],
          ...canvasProperties,
        };
        canvasParent.updatedAt = Date.now()
      }
    },
    removeDocumentBubbles: (state, { payload: { parentBubbleId, ids } }) => {
      const parentBubble = state.bubbles.find((b) => b.id === parentBubbleId);
      if (parentBubble) {
        parentBubble.documentChildren = parentBubble.documentChildren.filter(
          (id) => !ids.includes(id),
        );
        // do not remove the original bubbles if they still have references
        const orphanIds = ids.filter((id) => {
          !!state.bubbles.findIndex(
            (b) =>
              b.documentChildren.includes(id) ||
              b.canvasChildren.some((b) => b.id === id),
          );
        });

				state.bubbles = state.bubbles.filter((b) => !orphanIds.includes(b.id));
			}
		},
		removeSelectedCanvasBubbles: (state) => {
			const focusedBubble = state.bubbles.find(
				(b) => b.id === state.focusedBubbleId,
			);
			focusedBubble.canvasChildren = focusedBubble.canvasChildren.filter(
				(_b, index) => !state.selectedBubbles.includes(index),
			);
			state.selectedBubbles = [];
		},
		deleteBubble: (state, { payload: { selectedBubble } }) => {
			const focusedBubble = state.bubbles.find(
				(b) => b.id === state.focusedBubbleId,
			);
			focusedBubble.canvasChildren = focusedBubble.canvasChildren.filter(
				(_b, index) => !state.selectedBubbles.includes(index),
			);
			state.selectedBubbles = [];
		},
		resetState: () => {
			return getInitialState();
		},
		setBubbleUpdatedAt: (state, { payload: { id, updatedAt } }) => {
			const bubble = state.bubbles.find((b) => b.id === id);
			bubble.updatedAt = updatedAt;
		},
		setViewMode: (state, { payload: { viewMode } }) => {
			const bubble = state.bubbles.find((b) => b.id === state.focusedBubbleId);
			bubble.viewMode = viewMode;
		},
		stepIn: (state, { payload: { id } }) => {
			state.contextHistory.push(state.focusedBubbleId);
			state.focusedBubbleId = id;
			state.focusUpdated = Date.now();
		},
		stepOut: (state) => {
			if (state.contextHistory.length) {
				state.focusedBubbleId = state.contextHistory.pop();
			} else {
				// create a new bubble and use focusedBubbleId as the canvasChild of the newly create
				const id = uuidv4();
				const canvasBubble = {
					id: state.focusedBubbleId,
					...canvasBubbleDefaults,
				};

				const initialDocBubbleId = getInitialDocBubbleId();
				// add a document bubble to the new bubble
				state.bubbles.push({
					...initialBubble,
					id: initialDocBubbleId,
					updatedAt: Date.now(),
				});

				state.bubbles.push({
					...initialBubble,
					id,
					canvasChildren: [canvasBubble],
					documentChildren: [initialDocBubbleId],
					updatedAt: Date.now(),
				});
				state.focusedBubbleId = id;
				state.focusUpdated = Date.now();
			}
		},
		toggleExpanded: (state, { payload: { id } }) => {
			const bubble = state.bubbles.find((b) => b.id === id);
			bubble.expanded = !bubble.expanded;
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(handleTextGeneration.pending, (state) => {
				state.aiLoading = true;
			})
			.addCase(handleTextGeneration.fulfilled, (state) => {
				state.aiLoading = false;
			})
			.addCase(handleTextGeneration.rejected, (state) => {
				state.aiLoading = false;
			})
			.addCase(savePrompt.pending, (state) => {
				state.savingPrompt = true;
			})
			.addCase(savePrompt.fulfilled, (state, action) => {
				state.savingPrompt = false;
			})
			.addCase(savePrompt.rejected, (state, action) => {
				state.savingPrompt = false;
			})
			.addCase(fetchLatestPrompts.pending, (state) => {
				state.loadingLatestPrompts = true;
			})
			.addCase(fetchLatestPrompts.fulfilled, (state, action) => {
				state.latestPrompts = action.payload;
				state.loadingLatestPrompts = false;
			})
			.addCase(fetchLatestPrompts.rejected, (state, action) => {
				state.latestPromptsError = action.payload;
				state.loadingLatestPrompts = false;
			})
			.addCase(deleteBubble.pending, (state) => {
				state.loading = true;
			})
			.addCase(deleteBubble.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(deleteBubble.rejected, (state) => {
				state.loading = false;
			})
			.addCase(
				syncBubbles.fulfilled,
				(state, { payload: { syncedBubbles } }) => {
					syncedBubbles.forEach((syncedBubble) => {
						const bubble = state.bubbles.find((b) => b.id === syncedBubble.id);
						bubble.lastSyncedAt = Date.now();
						if (!bubble.updatedAt) {
							bubble.updatedAt = bubble.lastSyncedAt;
						}
					});
				},
			)
			.addCase(loadBubbles.pending, (state) => {
				state.loading = true;
			})
			.addCase(
				loadBubbles.fulfilled,
				(state, { payload: { bubbles, focusedBubbleId, focusSaved } }) => {
					state.loading = false;
					bubbles.forEach((b, i) => {
						if (!b) {
							return;
						}
						const existingBubbleIndex = state.bubbles.findIndex(
							(bubble) => bubble?.id === b?.id,
						);
						if (existingBubbleIndex > -1) {
							if (state.bubbles[existingBubbleIndex].updatedAt < b.updatedAt) {
								state.bubbles[existingBubbleIndex] = b;
							}
						} else {
							state.bubbles.push(b);
						}
					});
					if (
						focusedBubbleId &&
						state.bubbles.find((b) => b.id === focusedBubbleId) &&
						focusSaved > state.focusUpdated
					) {
						state.focusedBubbleId = focusedBubbleId;
						state.focusUpdated = Date.now();
					}
				},
			),
});

export const actions = bubblesSlice.actions;

export default bubblesSlice.reducer;
