import React from 'react';
import styles from './main-view.module.sass';

const TitleLoader = () => {

  return (
    <div className={`${styles.headerName} header-name`}>
      <p style={{opacity:0.5}}>      Loading your new title...      </p>
    </div>
  );
};

export default TitleLoader;
