import React, { Fragment } from 'react';
import {
  useBubblesActions,
  useBubblesState,
} from '../../../store/hooks/use-bubbles';
import BreadcrumbDropdownMenu from './dropdown-menu';
import styles from '../main-view.module.sass';
import { getBubbleName } from '../document/document-func';

const BreadcrumbItem = ({ items }) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const { loadBubbles, syncBubbles } = useBubblesActions();

  const onDropdownItemClick = (selectedItem) => {
    if (!selectedItem) return;
    else {
      syncBubbles();
      loadBubbles({ newFocusedBubbleId: selectedItem.id });
    }
  };

  const renderContent = (item) => getBubbleName(item) || 'Untitled';

  const itemsArray = Array.isArray(items) ? items : [items];
  const isDropdownRoot = itemsArray.length > 1;

  return (
    <Fragment>
      <div
        onClick={isDropdownRoot ? undefined : () => onDropdownItemClick(items[0])}
        onMouseEnter={isDropdownRoot ? () => setShowDropdown(true) : undefined}
        onMouseLeave={isDropdownRoot ? () => setShowDropdown(false) : undefined}
      >
        <div className={styles.breadcrumbTitle}>
          {isDropdownRoot ? 'Multiple parents' : renderContent(items[0])}
        </div>

        {isDropdownRoot && showDropdown && (
          <BreadcrumbDropdownMenu
            items={itemsArray}
            onSelect={onDropdownItemClick}
            getBubbleContent={renderContent}
          />
        )}
      </div>

      <div className={styles.breadcrumbSeparator}>▶</div>
    </Fragment>
  );
};

export default BreadcrumbItem;
