import React from "react";
import { defaultProps } from "@blocknote/core";
import { createReactBlockSpec } from "@blocknote/react";
import { checkListAliases } from "../../../utils/enums";
import styles from "./checklist-item.sass";

export const CheckListItem = createReactBlockSpec(
  {
    type: "checkListItem",
    propSchema: {
      checked: { default: false, values: [true, false] },
      alias: { values: Object.values(checkListAliases) },
      textAlignment: defaultProps.textAlignment,
      textColor: defaultProps.textColor,
    },
    content: "inline",
  },
  {
    render: ({ block, editor, contentRef }) => {
      const { checked, alias } = block.props;
      const isEmpty = block.content.length === 0;

      //get alias from previous checklist block
      const getPreviousAlias = () => {
        const prevBlock = editor.document.find(
          (b) => b.id !== block.id && b.type === "checkListItem"
        );
        return prevBlock?.props.alias || checkListAliases.CHECKLIST;
      };

      const placeholder = alias || getPreviousAlias();

      const toggleChecked = () =>
        editor.updateBlock(block, { props: { checked: !checked } });

      return (
        <div className={styles.checklistItem}>
          <input
            type="checkbox"
            checked={checked}
            onChange={toggleChecked}
            className={styles.checkbox}
          />
          <div
            ref={contentRef}
            className={`${styles.checklistContent} ${checked ? styles.checked : ""}`}
          >
            {isEmpty && <span className={styles.placeholder}>{placeholder}</span>}
          </div>
        </div>
      );
    },
  }
);
