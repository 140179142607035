import React, { forwardRef, useRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDndMonitor, useDraggable, useDroppable } from '@dnd-kit/core';
import { InlineDocument } from '../document/document';
import { useCombinedRefs } from '@dnd-kit/utilities';
import { ImSpinner4 } from "react-icons/im";


import NameEditor from './name-editor';
import Button from '../../common/button';

import { CANVAS_BUBBLE, DOCUMENT_BUBBLE } from '../../../types';

import styles from './canvas-bubble.module.sass';

import {
	useBubblesActions,
	useBubblesState,
} from '../../../store/hooks/use-bubbles';
import { Resizable } from 'react-resizable';
import ErrorBoundary from '../../error-boundary';
import { getBubbleName, setBubbleHistory } from '../document/document-func';
import TitleLoader from '../title-loader';
import { handleNameGeneration } from '../../../api';
import NameEditorLoader from './name-editor-loader';


const MaxAutoWidth = 500;
const MaxAutoHeight = 300;

// definitions:
// canvasIndex: the index of the bubble within the specific canvas array
// canvasParentId: the id of the bubble within whose canvas we are operating
let bubbleLocation;

const CanvasChildBubble = ({
	canvasIndex,
	canvasParentId,
	updateCount,
	id,
}) => {
	const { bubbles } = useBubblesState();
	const bubble = bubbles?.find((b) => b.id === id);

	return (
		<DraggableCanvasBubble
			canvasIndex={canvasIndex}
			canvasParentId={canvasParentId}
			updateCount={updateCount}
			bubble={bubble}
			attached
		/>
	);
};

const ChildBubbles = ({ parentBubble }) => {
	const { id: parentId, canvasChildren, expanded } = parentBubble;
	const { toggleExpanded } = useBubblesActions();

	if (!canvasChildren?.length) {
		return null;
	}

	return (
		<div className={classnames(styles.childBubbles, {
			[styles.expanded]: expanded,
			[styles.collapsed]: !expanded,
		})}>
			<button
				className={classnames(styles.childBubbleToggle, {
					[styles.expanded]: expanded,
				})}
				onClick={() => toggleExpanded({ id: parentId })}
			>
				<span>
					{expanded ? 'Hide' : 'Show'} inside ({canvasChildren.length})
				</span>
			</button>
			{expanded && (
				<div className={styles.childBubbleContainer}>
					{canvasChildren.map(({ id, updateCount }, i) => (
						<CanvasChildBubble
							canvasIndex={i}
							canvasParentId={parentId}
							updateCount={updateCount}
							key={`${id}-${i}`}
							id={id}
						/>
					))}
				</div>
			)}
		</div>
	);
};


export const DraggableCanvasBubble = ({
	updateCount,
	canvasIndex,
	canvasParentId,
	bubble,
	location,
	dimensions,
	attached = false,
	isSelectedDragging = false,
	focusedCanvasBubbleId,
	canvasFocus,
}) => {
	const { id } = bubble;
	const { selectedBubbles } = useBubblesState();
	const { moveCanvasChild, moveCanvasChildren, updateBubble } =
		useBubblesActions();

	const dragId = `${bubble.id}-${canvasIndex}`;

	// draggability
	const {
		attributes,
		listeners,
		setNodeRef: setDraggableNodeRef,
		isDragging,
	} = useDraggable({
		id: dragId,
		data: {
			type: CANVAS_BUBBLE,
			bubble,
			canvasIndex,
			canvasParentId,
			location,
			dimensions,
			id,
		},
	});
	bubbleLocation = location;
	const onDragEnd = (event) => {
		if (isOver) {
			const { active, over } = event;
			if (over?.data.current.accepts.includes(active.data.current.type)) {
				const draggedBubble = active.data.current.bubble;
				if (draggedBubble.id !== bubble.id && !isDragging) {
					const draggedCanvasIndex = active.data.current.canvasIndex;

					if (selectedBubbles?.includes(draggedCanvasIndex)) {
						moveCanvasChildren({
							canvasIndices: selectedBubbles,
							fromBubbleId: active.data.current.canvasParentId,
							toBubbleId: over.data.current.bubble.id,
						});
					} else {
						moveCanvasChildren({
							canvasIndices: [active.data.current.canvasIndex],
							fromBubbleId: active.data.current.canvasParentId,
							toBubbleId: over.data.current.bubble.id,
						});
					}
				}
			}
		}
	};

	useDndMonitor({
		onDragEnd,
	});

	// drop target (for adding as child bubbles)
	const {
		setNodeRef: setDroppableNodeRef,
		node: droppableRef,
		isOver,
	} = useDroppable({
		id: dragId,
		data: {
			accepts: [CANVAS_BUBBLE, DOCUMENT_BUBBLE],
			bubble,
		},
	});

	const setNodeRef = useCombinedRefs(setDroppableNodeRef, setDraggableNodeRef);

	const style = attached
		? null
		: {
			left: location.x,
			top: location.y,
			minWidth: dimensions.width,
			minHeight: dimensions.height,
		};

	if (isDragging || isSelectedDragging) {
		return null;
	}

	return (
		<CanvasBubble
			updateCount={updateCount}
			canvasIndex={canvasIndex}
			ref={setNodeRef}
			{...listeners}
			{...attributes}
			style={style}
			bubble={bubble}
			attached={attached}
			dimensions={dimensions}
			isOver={isOver}
			canvasParentId={canvasParentId}
			focusedCanvasBubbleId = {focusedCanvasBubbleId}
			canvasFocus = {canvasFocus}
		/>
	);
};

export const CanvasBubble = forwardRef(
  (
	{
	  updateCount,
	  canvasIndex,
	  canvasParentId,
	  className,
	  isOver,
	  attached = true,
	  dimensions,
	  bubble,
	  style,
	  offset,
	  focusedCanvasBubbleId,
	  canvasFocus,
	  ...props
	},
	ref,
  ) => {
	const bubbleLocationRef = useRef(null);
	const {
	  stepIn,
	  updateBubble,
	  updateCanvasBubbleProperties,
	  notifyCanvasBubbles,
	  updateDocumentBubble,
	  syncBubbles
	} = useBubblesActions();
	const { selectedBubbles, focusedBubbleId, focusedBubble, bubbles } = useBubblesState();
	const [isTitleLoading, setIsTitleLoading] = useState(false);
	const [hasResized, setHasResized] = useState(false);

		useEffect(() => {
			bubbleLocationRef.current = { x: style?.left, y: style?.top };
		}, [style]);

		const onStepIn = () => {
			stepIn({ id: bubble.id });
		};

	const onNameChange = (name) => {
	  if (name === bubble.name) return;
	  updateBubble({ id: bubble.id, properties: { name } });
	  // notify about update, any duplicates can then refresh
	  notifyCanvasBubbles({ id: bubble.id, canvasParentId, canvasIndex });
	};

  const generateName = async () => {
	let content = [];
	let contentBubbles = [];
	setIsTitleLoading(true);
  
	const traverseBubbles = (bubble, depth = 0, visited = new Set()) => {
	  if (visited.has(bubble.id)) {
		return; 
	  }
  
	  visited.add(bubble.id);
  
	  if (depth <= 5) {
		for (let i = 0; i < bubble.canvasChildren.length; i++) {
		  const childId = bubble.canvasChildren[i];
		  const childBubble = bubbles.find((b) => b.id === childId.id);
		  if (childBubble) {
			contentBubbles.push(childBubble);
			traverseBubbles(childBubble, depth + 1, visited);
		  }
		}
	  }
  
	  for (let i = 0; i < bubble.documentChildren.length; i++) {
		const childId = bubble.documentChildren[i];
		const childBubble = bubbles.find((b) => b.id === childId);
		if (childBubble) {
		  contentBubbles.push(childBubble);
		}
	  }
	};
  
	traverseBubbles(bubble);
  
	for (let i = 0; i < contentBubbles.length; i++) {
	  const text = getBubbleName(contentBubbles[i]);
	  if (text) {
		content.push(text);
	  }
	}
  
	const name = await handleNameGeneration({ content });
	console.log(content);
  
	let parsedName;
	if (bubble.name) {
	  parsedName = JSON.parse(bubble.name);
	}
  
	const updatedName = {
	  ...parsedName,
	  content: [{ type: "text", text: name, styles: {} }],
	};
  
	updateBubble({ id: bubble.id, properties: { name: JSON.stringify(updatedName) } });
	syncBubbles();
	setIsTitleLoading(false);
	window.dispatchEvent(new CustomEvent("TitleAICB", { detail: { name, id: bubble.id } }));
  };

		const updateHeightBeforeResize = (e) => {
			if(!hasResized){
				setHasResized(true)
				console.log("resized")
			}
			const bubbleLocation = bubbleLocationRef.current;
			if (!bubbleLocation) {
				console.error('bubbleLocation is not defined');
				return;
			}
			const MIN_HEIGHT = 100;
			const MIN_WIDTH = 300;
			const updatedHeight = Math.max((e.clientY-80) - bubbleLocation.y, MIN_HEIGHT);


			updateCanvasBubbleProperties({
				id: bubble.id,
				canvasIndex,
				canvasParentId,
				canvasProperties: {
					dimensions: { ...dimensions, height: updatedHeight },
				},
			});
		};


		const isSelected =
			canvasParentId === focusedBubbleId &&
			selectedBubbles.includes(canvasIndex);

		if (offset) {
			style = {
				...style,
				left: offset.x,
				top: offset.y,
				position: 'absolute',
			};
		}

		const MIN_WIDTH = 300;
		const MIN_HEIGHT = 100;

		if (dimensions) {
			style = {
				...style,
				minWidth: Math.max(MIN_WIDTH, dimensions.width),
				minHeight: Math.max(MIN_HEIGHT, dimensions.height),
			};
		}

		const resizeBubble = (_, { size }) => {
			console.log(size)
			updateCanvasBubbleProperties({
				id: bubble.id,
				canvasIndex,
				canvasParentId,
				canvasProperties: {
					dimensions: {
						width: size.width,
						height: size.height
					},
				},
			});
		};

		const enforceMinHeightOnResizeStop = (_, { size }) => {
			const MIN_WIDTH = 300; 
			const updatedWidth = Math.max(size.width, MIN_WIDTH);
		
			updateCanvasBubbleProperties({
				id: bubble.id,
				canvasIndex,
				canvasParentId,
				canvasProperties: {
					dimensions: {
						width: updatedWidth, 
						height:size.height
					},
				},
			});
		};
		

		return (
			<ErrorBoundary>
				<Resizable
					width={dimensions?.width}
					height={dimensions?.height}
					resizeHandles={attached ? [] : ['se']}
					handle={
						<div className={styles.resizeToggle}>
							<i className={classnames(styles.icon, styles.iconLink)} />
						</div>
					}
					onResizeStart={updateHeightBeforeResize}
					onResize={resizeBubble}
					onResizeStop={enforceMinHeightOnResizeStop}
				>
					<div
						className={classnames(className, styles.canvasBubble, {
							[styles.highlighted]: isOver || isSelected,
							[styles.attached]: attached,
							[styles.imageContent]: bubble.imageContent,
						})}
						style={style}
						ref={ref}
						onClick={(e) => e.stopPropagation()}
						onDoubleClick={(e) => e.stopPropagation()}
					>
						<div className={`${styles.header} header-name`} {...props}>
							{/* DEBUG {bubble.id}
							<div>X: {style?.left?.toFixed(0)}</div>
						<div>Y:  {style?.top?.toFixed(0)}</div>

						{canvasIndex}
						*/}

			  {!isTitleLoading ? <NameEditor
				bubbleId={bubble.id}
				initialName={bubble.name}
				onNameChange={onNameChange}
				updateCount={updateCount}
				sizeExpander = {resizeBubble}
				dimensions={dimensions}
				MaxAutoWidth={MaxAutoWidth}
				focusedCanvasBubbleId={focusedCanvasBubbleId}
				hasResized = {hasResized}
				canvasFocus={canvasFocus}
			  /> : <NameEditorLoader></NameEditorLoader>}

							{/*<input
							type="text"
							value={bubble.name}
							onChange={onNameChange}
							style={{
								width: bubble.name.length + 2 + 'ch',
								maxWidth: 'calc( 100% - 40px )',
							}}
						/>*/}
						</div>
						<Button styles={styles.titleGen} onClick={generateName} label="AI ✨" size="small" />
						<Button styles={styles.stepIn} onClick={onStepIn} label="Open" size="small" />
						<div className={styles.wrapper}>
							{bubble.imageContent ? (bubble.imageContent != "loading" ?
								<img src={bubble.imageContent} /> : <ImSpinner4 className={styles.spinner}/>
							) : (
								<InlineDocument
									updateCount={updateCount}
									canvasParentId={canvasParentId}
									parentCanvasIndex={canvasIndex}
									className={`${styles.document} canvasDocument`}
									bubble={bubble}
									dimensions={dimensions}
									sizeExpander = {resizeBubble}
									MaxAutoWidth = {MaxAutoWidth}
									MaxAutoHeight = {MaxAutoHeight}
									focusedCanvasBubbleId = {focusedCanvasBubbleId}
									hasResized = {hasResized}
									canvasFocus = {canvasFocus}
								/>
							)}
							<ChildBubbles parentBubble={bubble} />
						</div>
					</div>
				</Resizable>
			</ErrorBoundary>
		);
	},
);

CanvasBubble.displayName = 'CanvasBubble';

export default CanvasBubble;
