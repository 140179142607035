import React from 'react';

import styles from './name-editor.module.sass';

export function NameEditorLoader() {
  return (
    <div className={`${styles.editor} name-editor`}>
      <p style={{opacity:0.5}}>      Loading your new title...      </p>
    </div>
  );
}

export default NameEditorLoader;
